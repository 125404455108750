"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApolloOperationSerializer = void 0;
/**
 * Apollo Specific implementation of the Serializer interface.
 * Knows sanitize a MutationOptions object for storage in indexedDB
 */
exports.ApolloOperationSerializer = {
    serializeForStorage: function (_a) {
        var op = _a.op, qid = _a.qid;
        return {
            mutation: op.mutation,
            variables: op.variables,
            optimisticResponse: op.optimisticResponse,
            context: op.context
        };
    },
    deserializeFromStorage: function (item) {
        if (typeof item === "string") {
            item = JSON.parse(item);
        }
        return item;
    }
};
