"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OffixConfig = void 0;
var platform_1 = require("../utils/platform");
var DefaultOffixExecutor_1 = require("../utils/DefaultOffixExecutor");
var offix_offline_1 = require("offix-offline");
var store_1 = require("../store");
/**
 * Class for managing user and default configuration.
 * Default config is applied on top of user provided configuration
 */
var OffixConfig = /** @class */ (function () {
    function OffixConfig(options) {
        this.executor = options.executor || new DefaultOffixExecutor_1.DefaultOffixExecutor();
        if (options.networkStatus) {
            this.networkStatus = options.networkStatus;
        }
        else {
            this.networkStatus = (platform_1.isMobileCordova()) ?
                new offix_offline_1.CordovaNetworkStatus() : new offix_offline_1.WebNetworkStatus();
        }
        this.offlineStorage = options.storage || store_1.createDefaultOfflineStorage();
        this.serializer = options.serializer || new store_1.DefaultOfflineSerializer();
        this.offlineQueueListener = options.offlineQueueListener;
    }
    return OffixConfig;
}());
exports.OffixConfig = OffixConfig;
