"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSubscriptionOptions = exports.subscribeToMoreHelper = void 0;
var CacheOperation_1 = require("./api/CacheOperation");
var _1 = require(".");
/**
 * Helper function which can be used to call subscribeToMore for multiple SubscriptionHelperOptions
 * @param observableQuery the query which you would like to call subscribeToMore on.
 * @param arrayOfHelperOptions the array of `SubscriptionHelperOptions`
 */
exports.subscribeToMoreHelper = function (observableQuery, arrayOfHelperOptions) {
    for (var _i = 0, arrayOfHelperOptions_1 = arrayOfHelperOptions; _i < arrayOfHelperOptions_1.length; _i++) {
        var option = arrayOfHelperOptions_1[_i];
        observableQuery.subscribeToMore(exports.createSubscriptionOptions(option));
    }
};
/**
 * Creates a SubscribeToMoreOptions object which can be used with Apollo Client's subscribeToMore function
 * on an observable query.
 * @param options see `SubscriptionHelperOptions`
 */
exports.createSubscriptionOptions = function (options) {
    var subscriptionQuery = options.subscriptionQuery, cacheUpdateQuery = options.cacheUpdateQuery, operationType = options.operationType, _a = options.idField, idField = _a === void 0 ? "id" : _a, _b = options.returnField, returnField = _b === void 0 ? null : _b;
    var document = (subscriptionQuery && subscriptionQuery.query)
        || subscriptionQuery;
    var variables = (subscriptionQuery && subscriptionQuery.variables)
        || {};
    var query = (cacheUpdateQuery && cacheUpdateQuery.query)
        || cacheUpdateQuery;
    var queryField = _1.getOperationFieldName(query);
    return {
        document: document,
        variables: variables,
        updateQuery: function (prev, _a) {
            var _b, _c, _d;
            var subscriptionData = _a.subscriptionData;
            var data = subscriptionData.data;
            var key = Object.keys(data)[0];
            var mutadedItem = data[key];
            var optype = operationType;
            // necessary for relationships
            // i.e. comments on a task field
            var obj = (returnField)
                ? prev[queryField][returnField]
                : prev[queryField];
            var updater = getUpdateQueryFunction(optype, idField);
            var result = updater(obj, mutadedItem);
            if (!returnField) {
                return _b = {},
                    _b[queryField] = result,
                    _b;
            }
            return _c = {},
                _c[queryField] = __assign(__assign({}, prev[queryField]), (_d = {}, _d[returnField] = result, _d)),
                _c;
        }
    };
};
/**
 * Generate the standard update function to update the cache for a given operation type and query.
 * @param operationType The type of operation being performed
 * @param idField The id field the item keys off
 */
var getUpdateQueryFunction = function (operationType, idField) {
    if (idField === void 0) { idField = "id"; }
    if (operationType === CacheOperation_1.CacheOperation.ADD) {
        return addSubscriptionItem({ idField: idField });
    }
    if (operationType === CacheOperation_1.CacheOperation.REFRESH) {
        return updateSubscriptionItem({ idField: idField });
    }
    if (operationType === CacheOperation_1.CacheOperation.DELETE) {
        return deleteSubscriptionItem({ idField: idField });
    }
    // return a default function that does nothing
    return function (prev) { return prev; };
};
/**
 * returns a generic updateQuery function used to add a new item to a previous list of items.
 * may be exported in the future
 */
function addSubscriptionItem(_a) {
    var idField = _a.idField;
    return function (prev, newItem) {
        if (!newItem) {
            return prev;
        }
        return __spreadArrays(prev.filter(function (item) {
            return item[idField] !== newItem[idField];
        }), [newItem]);
    };
}
;
/**
 * returns a generic updateQuery function used to delete an item from a previous list of items.
 * may be exported in the future
 */
function deleteSubscriptionItem(_a) {
    var idField = _a.idField;
    return function (prev, newItem) {
        if (!newItem) {
            return [];
        }
        return prev.filter(function (item) { return item[idField] !== newItem[idField]; });
    };
}
;
/**
 * returns a generic updateQuery function used to update an item in a previous list of items.
 * may be exported in the future
 */
function updateSubscriptionItem(_a) {
    var idField = _a.idField;
    return function (prev, newItem) {
        if (!newItem) {
            return prev;
        }
        return prev.map(function (item) { return item[idField] === newItem[idField] ? newItem : item; });
    };
}
;
