"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultOfflineSerializer = void 0;
var DefaultOfflineSerializer = /** @class */ (function () {
    function DefaultOfflineSerializer() {
    }
    DefaultOfflineSerializer.prototype.serializeForStorage = function (_a) {
        var op = _a.op;
        return JSON.stringify(op);
    };
    DefaultOfflineSerializer.prototype.deserializeFromStorage = function (persistedEntry) {
        if (typeof persistedEntry === "string") {
            return JSON.parse(persistedEntry);
        }
        return persistedEntry;
    };
    return DefaultOfflineSerializer;
}());
exports.DefaultOfflineSerializer = DefaultOfflineSerializer;
