"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VersionedState = void 0;
/**
 * Object state manager using a version field
 * Allows moving to next state using the version field of the object
 *
 * VersionedObjectState requires GraphQL types to contain version field.
 * For example:
 *
 * type User {
 *   id: ID!
 *   version: String
 * }
 */
var VersionedState = /** @class */ (function () {
    function VersionedState() {
    }
    VersionedState.prototype.assignServerState = function (client, server) {
        client.version = server.version;
    };
    VersionedState.prototype.hasConflict = function (client, server) {
        return client.version !== server.version;
    };
    VersionedState.prototype.getStateFields = function () {
        // Id should be removed because we don't need to compare it for conflicts
        return ["version", "id"];
    };
    VersionedState.prototype.currentState = function (currentObjectState) {
        return currentObjectState.version;
    };
    return VersionedState;
}());
exports.VersionedState = VersionedState;
