"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createOptimisticResponse = void 0;
var helperFunctions_1 = require("./utils/helperFunctions");
var CacheOperation_1 = require("./api/CacheOperation");
var utils_1 = require("./utils");
/**
 * Create optimistic response.
 * For example:
 *
  optimisticResponse: {
      __typename: "Mutation",
      updateComment: {
        id: commentId,
        __typename: "Comment",
        content: commentContent
      }
    }
 *
 * For more info and examples see:
 * https://www.apollographql.com/docs/react/features/optimistic-ui.html
 *
 * @param mutation operation that is being performed (update)
 * @param returnType type that is going to be returned
 * @param variables actual data passed to function
 * @param idField name of id field (default:id)
 * @param operationType the type of operation being returned
 */
exports.createOptimisticResponse = function (options) {
    var operation = helperFunctions_1.getOperationFieldName(options.mutation);
    // TODO things get really bad if returnType is not provided
    var returnType = options.returnType, variables = options.variables, _a = options.idField, idField = _a === void 0 ? "id" : _a, operationType = options.operationType;
    var optimisticResponse = {
        __typename: "Mutation"
    };
    var mappedVariables = variables;
    if (options.inputMapper) {
        mappedVariables = options.inputMapper.deserialize(variables);
    }
    optimisticResponse[operation] = __assign(__assign({ __typename: returnType }, mappedVariables), { optimisticResponse: true });
    if (operationType === CacheOperation_1.CacheOperation.ADD && !optimisticResponse[operation][idField]) {
        optimisticResponse[operation][idField] = utils_1.generateClientId();
    }
    return optimisticResponse;
};
