"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalConflictError = void 0;
/**
 * Local conflict thrown when data outdates even before sending it to the server.
 * Can be used to correct any data in flight or shown user another UI to visualize new state
 *
 * Local conflict happens when user opens view with cached data and in the mean time
 * cache gets updated by subscriptions. In this case it makes no sense to send request to server as we know
 * that data was outdated. Developers need to handle this use case instantly
 * (instead enqueuing data for offline processing)
 */
var LocalConflictError = /** @class */ (function (_super) {
    __extends(LocalConflictError, _super);
    function LocalConflictError(conflictBase, variables) {
        var _this = _super.call(this) || this;
        _this.conflictBase = conflictBase;
        _this.variables = variables;
        /**
         * Flag used to recognize this type of error
         */
        _this.localConflict = true;
        return _this;
    }
    return LocalConflictError;
}(Error));
exports.LocalConflictError = LocalConflictError;
