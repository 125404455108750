"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseStateFromCache = void 0;
var offix_conflicts_client_1 = require("offix-conflicts-client");
/**
 * BaseProcessor takes an outgoing GraphQL operation and it checks if it
 * is a mutation on an object we already know about in our local cache
 * If it is, then we need to get the 'base' which is the original object we are trying to mutate
 *
 * We first check for a 'local conflict' which happens when the base from the cache is different from
 * the 'version' the operation is trying to mutate.
 * this can happen when the mutation is in flight but the cache was updated by subscriptions
 *
 * If we have no local conflict, we add the original base to the operation's context,
 * which can then be used for conflict resolution later on.
 */
function getBaseStateFromCache(cache, objectState, mutationOptions, inputMapper) {
    var context = mutationOptions.context;
    if (!context.conflictBase) {
        var mutationVariables = mutationOptions.variables;
        if (inputMapper) {
            mutationVariables = inputMapper.deserialize(mutationVariables);
        }
        var conflictBase = getObjectFromCache(cache, context.returnType, mutationVariables);
        if (conflictBase && Object.keys(conflictBase).length !== 0) {
            if (objectState.hasConflict(mutationVariables, conflictBase)) {
                // 🙊 Input data is conflicted with the latest server projection
                throw new offix_conflicts_client_1.LocalConflictError(conflictBase, mutationOptions.variables);
            }
            return conflictBase;
        }
    }
}
exports.getBaseStateFromCache = getBaseStateFromCache;
function getObjectFromCache(cache, typename, mutationVariables) {
    if (cache && cache.data) {
        var idKey = cache.config.dataIdFromObject(__assign({ __typename: typename }, mutationVariables));
        if (cache.optimisticData && cache.optimisticData.parent) {
            var optimisticData = cache.optimisticData.parent.data;
            if (idKey && optimisticData[idKey]) {
                // return copy of original object
                return Object.assign({}, optimisticData[idKey]);
            }
        }
        var cacheData = cache.data.data;
        if (idKey && cacheData[idKey]) {
            // return copy of original object
            return Object.assign({}, cacheData[idKey]);
        }
    }
    else {
        console.warn("Client is missing cache implementation. Conflict features will not work properly");
    }
    return {};
}
