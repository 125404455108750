"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultLink = void 0;
var apollo_link_1 = require("apollo-link");
var apollo_link_retry_1 = require("apollo-link-retry");
var helpers_1 = require("./helpers");
var ConflictLink_1 = require("./conflicts/ConflictLink");
/**
 * Default HTTP Apollo Links
 * Provides out of the box functionality for:
 *
 * - Offline/Online queue
 * - Conflict resolution
 * - Error handling
 */
function createDefaultLink(config) {
    var conflictLink = new ConflictLink_1.ConflictLink({
        conflictProvider: config.conflictProvider,
        conflictListener: config.conflictListener,
        conflictStrategy: config.conflictStrategy,
        inputMapper: config.inputMapper
    });
    var retryLink = apollo_link_1.ApolloLink.split(helpers_1.isMarkedOffline, new apollo_link_retry_1.RetryLink(config.retryOptions));
    if (!config.link) {
        throw new Error("config missing link property");
    }
    var links = [conflictLink, retryLink, config.link];
    return apollo_link_1.ApolloLink.from(links);
}
exports.createDefaultLink = createDefaultLink;
