"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMarkedOffline = exports.isNetworkError = exports.isMutation = exports.isSubscription = void 0;
var apollo_utilities_1 = require("apollo-utilities");
exports.isSubscription = function (op) {
    var _a = apollo_utilities_1.getMainDefinition(op.query), kind = _a.kind, operation = _a.operation;
    return kind === "OperationDefinition" && operation === "subscription";
};
exports.isMutation = function (op) {
    var _a = apollo_utilities_1.getMainDefinition(op.query), kind = _a.kind, operation = _a.operation;
    return kind === "OperationDefinition" && operation === "mutation";
};
exports.isNetworkError = function (error) {
    return !error.result;
};
exports.isMarkedOffline = function (operation) {
    return !!operation.getContext().isOffline;
};
