"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConflictHandler = void 0;
/**
 * Filtered conflict handler enables tree stage conflict resolution:
 *
 * - checks if data conflicts exist
 * - perform resolution for conflicts or merge data otherwise
 * - notify listeners about merge/conflict
 */
var ConflictHandler = /** @class */ (function () {
    function ConflictHandler(options) {
        this.conflicted = false;
        this.clientDiff = {};
        this.serverDiff = {};
        this.options = options;
        this.ignoredKeys = this.options.objectState.getStateFields();
        this.checkConflict();
    }
    /**
     * Executes the supplied strategy for each handler
     */
    ConflictHandler.prototype.executeStrategy = function () {
        var serverState = Object.assign({}, this.options.server);
        var resolvedData = this.options.strategy.resolve({
            base: this.options.base,
            server: this.options.server,
            serverDiff: this.serverDiff,
            client: this.options.client,
            clientDiff: this.clientDiff,
            operation: this.options.operationName
        });
        this.options.objectState.assignServerState(resolvedData, serverState);
        if (this.options.listener) {
            if (this.conflicted) {
                this.options.listener.conflictOccurred(this.options.operationName, resolvedData, this.options.server, this.options.client);
            }
            else if (this.options.listener.mergeOccurred) {
                this.options.listener.mergeOccurred(this.options.operationName, resolvedData, this.options.server, this.options.client);
            }
        }
        // Filter to send only original data from client
        var filteredData = {};
        for (var _i = 0, _a = Object.keys(resolvedData); _i < _a.length; _i++) {
            var key = _a[_i];
            if (this.options.client[key]) {
                filteredData[key] = resolvedData[key];
            }
        }
        return filteredData;
    };
    ConflictHandler.prototype.checkConflict = function () {
        var base = this.options.base;
        var client = this.options.client;
        var server = this.options.server;
        if (!base || !client || !server) {
            return;
        }
        // calculate client diff
        for (var _i = 0, _a = Object.keys(client); _i < _a.length; _i++) {
            var key = _a[_i];
            if (base[key] && base[key] !== client[key]) {
                if (!this.ignoredKeys.includes(key)) {
                    this.clientDiff[key] = client[key];
                }
            }
        }
        // calculate server diff
        for (var _b = 0, _c = Object.keys(this.options.client); _b < _c.length; _b++) {
            var key = _c[_b];
            if (base[key] && base[key] !== server[key]) {
                if (!this.ignoredKeys.includes(key)) {
                    this.serverDiff[key] = server[key];
                    if (this.clientDiff[key]) {
                        this.conflicted = true;
                    }
                }
            }
        }
    };
    return ConflictHandler;
}());
exports.ConflictHandler = ConflictHandler;
