"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UseClient = void 0;
// Used as default strategy for SDK
exports.UseClient = {
    resolve: function (_a) {
        var server = _a.server, clientDiff = _a.clientDiff;
        return Object.assign(server, clientDiff);
    }
};
