"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUpdateFunction = exports.createMutationOptions = void 0;
var CacheOperation_1 = require("./api/CacheOperation");
var createOptimisticResponse_1 = require("./createOptimisticResponse");
var helperFunctions_1 = require("./utils/helperFunctions");
/**
 * Creates a MutationOptions object which can be used with Apollo Client's mutate function
 * Provides useful helpers for cache updates, optimistic responses, and context
 * @param options see `MutationHelperOptions`
 */
exports.createMutationOptions = function (options) {
    var mutation = options.mutation, variables = options.variables, updateQuery = options.updateQuery, returnType = options.returnType, _a = options.operationType, operationType = _a === void 0 ? CacheOperation_1.CacheOperation.ADD : _a, _b = options.idField, idField = _b === void 0 ? "id" : _b, context = options.context, inputMapper = options.inputMapper;
    if (returnType && !options.optimisticResponse) {
        options.optimisticResponse = createOptimisticResponse_1.createOptimisticResponse({
            mutation: mutation,
            variables: variables,
            returnType: returnType,
            operationType: operationType,
            idField: idField,
            inputMapper: inputMapper
        });
    }
    var mutationName = helperFunctions_1.getOperationFieldName(mutation);
    if (updateQuery) {
        var update = function (cache, _a) {
            var data = _a.data;
            if (Array.isArray(updateQuery)) {
                for (var _i = 0, updateQuery_1 = updateQuery; _i < updateQuery_1.length; _i++) {
                    var query = updateQuery_1[_i];
                    var updateFunction = exports.getUpdateFunction({
                        mutationName: mutationName,
                        idField: idField,
                        operationType: operationType,
                        updateQuery: query
                    });
                    updateFunction(cache, { data: data });
                }
            }
            else {
                var updateFunction = exports.getUpdateFunction({
                    mutationName: mutationName,
                    idField: idField,
                    operationType: operationType,
                    updateQuery: updateQuery
                });
                updateFunction(cache, { data: data });
            }
        };
        options.update = update;
    }
    options.context = __assign(__assign({}, context), { returnType: returnType, idField: idField, operationName: mutationName, isOffline: true });
    return options;
};
/**
 * Generate the update function to update the cache for a given operation and query.
 **/
exports.getUpdateFunction = function (options) {
    if (!options.updateQuery) {
        throw new Error("Required updateQuery parameter is not supplied");
    }
    if (!options.mutationName) {
        throw new Error("Required mutationName parameter is not supplied");
    }
    var _a = options.operationType, operationType = _a === void 0 ? CacheOperation_1.CacheOperation.ADD : _a, cacheHelperOptions = __rest(options, ["operationType"]);
    if (operationType === CacheOperation_1.CacheOperation.ADD) {
        return addItemToQuery(cacheHelperOptions);
    }
    if (operationType === CacheOperation_1.CacheOperation.DELETE) {
        return deleteItemFromQuery(cacheHelperOptions);
    }
    // this default catches the REFRESH case and returns an empty update function which does nothing
    // eslint-disable-next-line
    return function () { };
};
/**
 * Generic cache update function that adds an item to a query that contains a list of items
 * Might be exported in the future
 */
function addItemToQuery(_a) {
    var mutationName = _a.mutationName, updateQuery = _a.updateQuery, _b = _a.idField, idField = _b === void 0 ? "id" : _b, _c = _a.returnField, returnField = _c === void 0 ? null : _c;
    return function (cache, _a) {
        var data = _a.data;
        var _b = helperFunctions_1.deconstructQuery(updateQuery), query = _b.query, variables = _b.variables;
        var queryField = helperFunctions_1.getOperationFieldName(query);
        var queryResult;
        if (data) {
            var operationData_1 = data[mutationName];
            try {
                queryResult = cache.readQuery({ query: query, variables: variables });
            }
            catch (e) {
                queryResult = {};
            }
            var result = (returnField)
                ? queryResult[queryField][returnField]
                : queryResult[queryField];
            if (result && operationData_1) {
                // FIXME deduplication should happen on subscriptions
                // We do that every time no matter if we have subscription
                if (result instanceof Array) {
                    var foundItem = !result.find(function (item) {
                        return item[idField] === operationData_1[idField];
                    });
                    if (foundItem) {
                        result.push(operationData_1);
                    }
                }
            }
            else {
                if (!returnField) {
                    queryResult[queryField] = [operationData_1];
                }
                else {
                    queryResult[queryField][returnField] = [operationData_1];
                }
            }
            try {
                cache.writeQuery({
                    query: query,
                    variables: variables,
                    data: queryResult
                });
                // tslint:disable-next-line: no-empty
            }
            finally {
            }
        }
    };
}
/**
 * Generic cache update function that removes an item from a query that contains a list of items
 * Might be exported in the future
 */
function deleteItemFromQuery(_a) {
    var mutationName = _a.mutationName, updateQuery = _a.updateQuery, _b = _a.idField, idField = _b === void 0 ? "id" : _b, _c = _a.returnField, returnField = _c === void 0 ? null : _c;
    return function (cache, _a) {
        var data = _a.data;
        var _b = helperFunctions_1.deconstructQuery(updateQuery), query = _b.query, variables = _b.variables;
        var queryField = helperFunctions_1.getOperationFieldName(query);
        if (data) {
            try {
                var queryResult = cache.readQuery({ query: query, variables: variables });
                var operationData = data[mutationName];
                if (operationData) {
                    var toBeRemoved_1 = {};
                    if (typeof operationData === "string") {
                        toBeRemoved_1[idField] = operationData;
                    }
                    else {
                        toBeRemoved_1 = operationData;
                    }
                    var newData = void 0;
                    var prev = (returnField)
                        ? queryResult[queryField][returnField]
                        : queryResult[queryField];
                    if (prev instanceof Array) {
                        newData = prev.filter(function (item) {
                            return toBeRemoved_1[idField] !== item[idField];
                        });
                    }
                    else {
                        if (!returnField) {
                            newData = queryResult[queryField];
                        }
                        else {
                            newData = queryResult[queryField][returnField];
                        }
                    }
                    queryResult[queryField] = newData;
                    cache.writeQuery({
                        query: query,
                        variables: variables,
                        data: queryResult
                    });
                }
                // tslint:disable-next-line: no-empty
            }
            finally {
            }
        }
    };
}
