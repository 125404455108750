"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deconstructQuery = exports.getOperationFieldName = exports.getMutationName = exports.generateClientId = exports.isClientGeneratedId = void 0;
var apollo_utilities_1 = require("apollo-utilities");
var constants_1 = require("./constants");
// Returns true if ID was generated on client
exports.isClientGeneratedId = function (id) {
    return typeof id === "string" && id.startsWith(constants_1.CLIENT_ID_PREFIX);
};
// Helper method for ID generation ()
exports.generateClientId = function (length) {
    if (length === void 0) { length = 8; }
    var result = constants_1.CLIENT_ID_PREFIX;
    var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = length; i > 0; i -= 1) {
        result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
};
exports.getMutationName = function (mutation) {
    var definition = mutation.definitions.find(function (def) { return def.kind === "OperationDefinition"; });
    var operationDefinition = definition && definition;
    return operationDefinition && operationDefinition.name && operationDefinition.name.value;
};
exports.getOperationFieldName = function (operation) { return apollo_utilities_1.resultKeyNameFromField(operation.definitions[0].selectionSet.selections[0]); };
// this function takes a Query and returns its constituent parts, if available.
exports.deconstructQuery = function (updateQuery) {
    var query;
    var variables;
    if (isQueryWithVariables(updateQuery)) {
        query = updateQuery.query;
        variables = updateQuery.variables;
    }
    else {
        query = updateQuery;
    }
    return { query: query, variables: variables };
};
var isQueryWithVariables = function (doc) {
    if (doc.variables) {
        return true;
    }
    else {
        return false;
    }
};
