"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateConfig = void 0;
var ConfigError_1 = require("./ConfigError");
function validateConfig(config) {
    checkFields(config, [
        "cacheStorage", "offlineStorage", "conflictListener", "cache"
    ]);
}
exports.validateConfig = validateConfig;
function checkFields(obj, fields) {
    fields.forEach(function (fieldName) {
        if (obj[fieldName] === undefined) {
            throw new ConfigError_1.ConfigError("Offix client config error: " + fieldName + " is required", fieldName);
        }
    });
}
