"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfflineError = void 0;
/**
 *
 * Represents error object returned to user when offline
 *
 * Usage:
 * ```javascript
 * client.mutate(...).catch((error)=> {
 *  if(error.networkError && error.networkError.offline){
 *    const offlineError: OfflineError =  error.networkError;
 *    offlineError.watchOfflineChange().then(...)
 *  }
 * });
 * ```
 */
var OfflineError = /** @class */ (function () {
    function OfflineError(offlineMutatePromise) {
        this.offline = true;
        this.offlineMutatePromise = offlineMutatePromise;
    }
    OfflineError.prototype.watchOfflineChange = function () {
        return this.offlineMutatePromise;
    };
    return OfflineError;
}());
exports.OfflineError = OfflineError;
