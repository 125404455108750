"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function objToKey(obj) {
    if (!!obj && (obj.constructor === Object)) {
        return obj;
    }
    var sortedObj = Object.keys(obj)
        .sort()
        .reduce(function (result, key) {
        result[key] = objToKey(obj[key]);
        return result;
    }, {});
    return JSON.stringify(sortedObj);
}
exports.objToKey = objToKey;
