"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OffixScheduler = void 0;
var OffixConfig_1 = require("./config/OffixConfig");
var OfflineError_1 = require("./OfflineError");
var queue_1 = require("./queue");
var store_1 = require("./store");
/**
 * OffixScheduler is a scheduler that queues function calls when
 * an application is considered offline and fulfills them
 * later when the app is back online.
 *
 * The action or function being scheduled can be anything
 * but it is typically reliant on the network and is usually
 * something that causes a server side change
 * e.g. a HTTP Request, Sending a Message, a GraphQL Mutation
 *
 * Offix queues all operations in order and fulfills them when back online.
 * It also persists them, allowing the operations to be kept across app restarts.
 *
 */
var OffixScheduler = /** @class */ (function () {
    function OffixScheduler(options) {
        if (options === void 0) { options = {}; }
        // listeners that can be added by the user to handle various events coming from the offline queue
        this.queueListeners = [];
        // determines whether we're offline or not
        this.online = false;
        this.config = new OffixConfig_1.OffixConfig(options);
        this.networkStatus = this.config.networkStatus;
        this.offlineStore = new store_1.OfflineStore(this.config.offlineStorage, this.config.serializer);
        if (this.config.offlineQueueListener) {
            this.queueListeners.push(this.config.offlineQueueListener);
        }
        this.executor = this.config.executor;
        this.queue = new queue_1.OfflineQueue(this.offlineStore, {
            listeners: this.queueListeners,
            networkStatus: this.networkStatus,
            // TODO this needs to be revisited. What context should the execute function have?
            // Should it be able to access things on the Offix scheduler?
            execute: this.executor.execute.bind(this.executor)
        });
    }
    /**
    * Initialize the scheduler
    */
    OffixScheduler.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.offlineStore.init()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.queue.restoreOfflineOperations()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error("Error initializing storage for offline queue", error_1);
                        console.error("Offline mutations will not be persisted across restarts");
                        return [3 /*break*/, 4];
                    case 4: return [4 /*yield*/, this.initOnlineState()];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Add new listener for listening for queue changes
     *
     * @param listener
     */
    OffixScheduler.prototype.registerOfflineQueueListener = function (listener) {
        this.queue.registerOfflineQueueListener(listener);
    };
    /**
     * The scheduler execute method. Schedules an operation and its options to be
     * fulfilled when online
     *
     * @param options the options as expected by the Executor
     */
    OffixScheduler.prototype.execute = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var queueEntry, mutationPromise;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.online) return [3 /*break*/, 1];
                        return [2 /*return*/, this.executor.execute(options)];
                    case 1: return [4 /*yield*/, this.queue.enqueueOperation(options)];
                    case 2:
                        queueEntry = _a.sent();
                        mutationPromise = this.queue.buildPromiseForEntry(queueEntry);
                        // throw an error with a reference to the promise
                        throw new OfflineError_1.OfflineError(mutationPromise);
                }
            });
        });
    };
    OffixScheduler.prototype.initOnlineState = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queue, self, _a, listener;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        queue = this.queue;
                        self = this;
                        _a = this;
                        return [4 /*yield*/, this.networkStatus.isOffline()];
                    case 1:
                        _a.online = !(_b.sent());
                        if (this.online) {
                            queue.forwardOperations();
                        }
                        listener = function (networkInfo) {
                            self.online = networkInfo.online;
                            if (self.online) {
                                queue.forwardOperations();
                            }
                        };
                        this.networkStatus.addListener(listener);
                        return [2 /*return*/];
                }
            });
        });
    };
    return OffixScheduler;
}());
exports.OffixScheduler = OffixScheduler;
