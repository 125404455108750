"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDBLocalStore = void 0;
var IDBLocalStore = /** @class */ (function () {
    function IDBLocalStore(dbName, storeName) {
        if (dbName === void 0) { dbName = "graphqlstore"; }
        if (storeName === void 0) { storeName = "keyval"; }
        this.storeName = storeName;
        this._dbp = new Promise(function (resolve, reject) {
            var openreq = indexedDB.open(dbName, 1);
            openreq.onerror = function () { return reject(openreq.error); };
            openreq.onsuccess = function () { return resolve(openreq.result); };
            // First time setup: create an empty object store
            openreq.onupgradeneeded = function () {
                openreq.result.createObjectStore(storeName);
            };
        });
    }
    IDBLocalStore.prototype._withIDBStore = function (type, callback) {
        var _this = this;
        return this._dbp.then(function (db) { return new Promise(function (resolve, reject) {
            var transaction = db.transaction(_this.storeName, type);
            transaction.oncomplete = function () { return resolve(); };
            transaction.onabort = transaction.onerror = function () { return reject(transaction.error); };
            callback(transaction.objectStore(_this.storeName));
        }); });
    };
    IDBLocalStore.prototype.getItem = function (key) {
        var req;
        return this._withIDBStore("readonly", function (store) {
            req = store.get(key);
        }).then(function () { return req.result; });
    };
    IDBLocalStore.prototype.setItem = function (key, value) {
        return this._withIDBStore("readwrite", function (store) {
            store.put(value, key);
        });
    };
    IDBLocalStore.prototype.removeItem = function (key) {
        return this._withIDBStore("readwrite", function (store) {
            store.delete(key);
        });
    };
    IDBLocalStore.prototype.clear = function () {
        return this._withIDBStore("readwrite", function (store) {
            store.clear();
        });
    };
    IDBLocalStore.prototype.keys = function () {
        var keys = [];
        return this._withIDBStore("readonly", function (store) {
            // This would be store.getAllKeys(), but it isn't supported by Edge or Safari.
            // And openKeyCursor isn't supported by Safari.
            // eslint-disable-next-line
            (store.openKeyCursor || store.openCursor).call(store).onsuccess = function () {
                if (!this.result) {
                    return;
                }
                keys.push(this.result.key);
                this.result.continue();
            };
        }).then(function () { return keys; });
    };
    return IDBLocalStore;
}());
exports.IDBLocalStore = IDBLocalStore;
