"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConflictLink = void 0;
var apollo_link_error_1 = require("apollo-link-error");
var apollo_link_1 = require("apollo-link");
var offix_conflicts_client_1 = require("offix-conflicts-client");
var helpers_1 = require("../helpers");
/**
 * Conflict handling link implementation that provides ability to determine whether or not a conflict should be handled.
 * Leverages Apollo's onError link to keep track of the observables and the retried operations.
 */
var ConflictLink = /** @class */ (function (_super) {
    __extends(ConflictLink, _super);
    function ConflictLink(config) {
        var _this = _super.call(this) || this;
        _this.config = config;
        _this.link = apollo_link_error_1.onError(_this.conflictHandler.bind(_this));
        _this.stater = _this.config.conflictProvider;
        _this.strategy = _this.config.conflictStrategy;
        _this.listener = _this.config.conflictListener;
        return _this;
    }
    ConflictLink.prototype.request = function (operation, forward) {
        if (helpers_1.isMutation(operation)) {
            var variables = this.config.inputMapper ? this.config.inputMapper.deserialize(operation.variables) : operation.variables;
            if (this.stater.currentState(variables) !== undefined) {
                return this.link.request(operation, forward);
            }
        }
        return forward(operation);
    };
    // this is a custom onError ErrorHandler. It determines executes the conflictHandler and provides a new operation
    // to work with if necessary.
    ConflictLink.prototype.conflictHandler = function (errorResponse) {
        var operation = errorResponse.operation, forward = errorResponse.forward, graphQLErrors = errorResponse.graphQLErrors;
        var data = this.getConflictData(graphQLErrors);
        var individualStrategy = this.strategy || offix_conflicts_client_1.UseClient;
        if (data && operation.getContext().returnType) {
            var base = operation.getContext().conflictBase;
            var conflictHandler = new offix_conflicts_client_1.ConflictHandler({
                base: base,
                client: data.clientState,
                server: data.serverState,
                strategy: individualStrategy,
                listener: this.listener,
                objectState: this.config.conflictProvider,
                operationName: operation.operationName
            });
            var resolvedConflict = conflictHandler.executeStrategy();
            if (resolvedConflict) {
                operation.variables = this.config.inputMapper ? this.config.inputMapper.serialize(resolvedConflict) : resolvedConflict;
            }
        }
        return forward(operation);
    };
    /**
    * Fetch conflict data from the errors returned from the server
    * @param graphQLErrors array of errors to retrieve conflicted data from
    */
    ConflictLink.prototype.getConflictData = function (graphQLErrors) {
        if (graphQLErrors) {
            for (var _i = 0, graphQLErrors_1 = graphQLErrors; _i < graphQLErrors_1.length; _i++) {
                var err = graphQLErrors_1[_i];
                if (err.extensions && err.extensions.exception && err.extensions.exception.conflictInfo) {
                    return err.extensions.exception.conflictInfo;
                }
            }
        }
    };
    return ConflictLink;
}(apollo_link_1.ApolloLink));
exports.ConflictLink = ConflictLink;
