"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMobileCordova = void 0;
/**
 * Detect Cordova / PhoneGap / Ionic frameworks on a mobile device.
 *
 * Deliberately does not rely on checking `file://` URLs (as this fails PhoneGap in the Ripple emulator) nor
 * Cordova `onDeviceReady`, which would normally wait for a callback.
 *
 * @return {boolean} isMobileCordova
 */
exports.isMobileCordova = function () {
    return window && !!window["cordova"];
};
