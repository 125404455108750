"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var ApolloOfflineContext = react_1.default.createContext(null);
/**
 *
 * The ApolloOfflineProvider is a Context Provider that lets us initialize the
 * offix client once in our app and then we can access it from any other component in our app
 * using `useOffixClient`
 */
function ApolloOfflineProvider(_a) {
    var client = _a.client, children = _a.children;
    return (react_1.default.createElement(ApolloOfflineContext.Provider, { value: client }, children));
}
exports.ApolloOfflineProvider = ApolloOfflineProvider;
function useApolloOfflineClient(overrideClient) {
    var client = react_1.useContext(ApolloOfflineContext);
    // Ensures that the number of hooks called from one render to another remains
    // constant, despite the Apollo client read from context being swapped for
    // one passed directly as prop.
    if (overrideClient) {
        return overrideClient;
    }
    if (!client) {
        throw new Error('Could not find "client" in the context or passed in as a prop. ' +
            'Wrap the root component in an <ApolloOfflineProvider>, or pass an ' +
            'ApolloOfflineProvider instance in via props.');
    }
    return client;
}
exports.useApolloOfflineClient = useApolloOfflineClient;
