"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfflineQueue = void 0;
/**
 * Class implementing persistent operation queue.
 *
 * This class is designed to be used by OfflineLink
 * It provides these functionalities:
 *
 * - persisting operation queue in provided storage
 * - updating client IDs with server IDs (explained below)
 */
var OfflineQueue = /** @class */ (function () {
    function OfflineQueue(store, options) {
        this.entries = [];
        // listeners that can be added by the user to handle various events coming from the offline queue
        this.listeners = [];
        this.store = store;
        this.execute = options.execute;
        if (options.listeners) {
            this.listeners = options.listeners;
        }
    }
    /**
     * Enqueue offline change and wait for it to be sent to server when online.
     * Every offline change is added to queue.
     */
    OfflineQueue.prototype.enqueueOperation = function (op) {
        return __awaiter(this, void 0, void 0, function () {
            var entry, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        entry = {
                            operation: {
                                qid: ID(),
                                op: op
                            }
                        };
                        // enqueue and persist
                        this.entries.push(entry);
                        // notify listeners
                        this.onOperationEnqueued(entry.operation);
                        if (!this.store.initialized) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.store.saveEntry(entry.operation)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.error(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, entry];
                }
            });
        });
    };
    OfflineQueue.prototype.buildPromiseForEntry = function (entry) {
        return new Promise(function (resolve, reject) {
            entry.handlers = {
                resolve: resolve,
                reject: reject
            };
        });
    };
    OfflineQueue.prototype.dequeueOperation = function (entry) {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.entries = this.entries.filter(function (e) { return e !== entry; });
                        if (!this.store.initialized) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.store.removeEntry(entry.operation)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        // TODO integration test for this
                        // TODO should we log something here?
                        console.error(err_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    OfflineQueue.prototype.updateOperation = function (operation) {
        return __awaiter(this, void 0, void 0, function () {
            var qid, index;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        qid = operation.qid;
                        index = this.entries.findIndex(function (entry) { return entry.operation.qid === qid; });
                        if (index === -1) {
                            throw new Error("error updating item in queue could not find entry for operation " + operation);
                        }
                        this.entries[index].operation = operation;
                        return [4 /*yield*/, this.store.saveEntry(operation)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OfflineQueue.prototype.forwardOperations = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _i, _a, entry;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _i = 0, _a = this.entries;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        entry = _a[_i];
                        return [4 /*yield*/, this.forwardOperation(entry)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    OfflineQueue.prototype.forwardOperation = function (entry) {
        return __awaiter(this, void 0, void 0, function () {
            var result, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.execute(entry.operation.op)];
                    case 1:
                        result = _a.sent();
                        this.onForwardNext(entry, result);
                        if (entry.handlers) {
                            entry.handlers.resolve(result);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        if (entry.handlers) {
                            entry.handlers.reject(error_1);
                        }
                        this.onOperationFailure(entry.operation, error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OfflineQueue.prototype.restoreOfflineOperations = function () {
        return __awaiter(this, void 0, void 0, function () {
            var offlineEntries, _i, offlineEntries_1, entry, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.store.initialized) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.store.getOfflineData()];
                    case 2:
                        offlineEntries = _a.sent();
                        for (_i = 0, offlineEntries_1 = offlineEntries; _i < offlineEntries_1.length; _i++) {
                            entry = offlineEntries_1[_i];
                            this.onOperationRequeued(entry.operation);
                        }
                        this.entries = offlineEntries;
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        // TODO should we be logging something here?
                        // TODO integration tests covering this
                        // Possible reasons for failure are
                        // no storage available
                        // or the deserializeFromStorage function failed
                        console.error(error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    OfflineQueue.prototype.registerOfflineQueueListener = function (listener) {
        this.listeners.push(listener);
    };
    OfflineQueue.prototype.onOperationEnqueued = function (op) {
        for (var _i = 0, _a = this.listeners; _i < _a.length; _i++) {
            var listener = _a[_i];
            if (listener.onOperationEnqueued) {
                listener.onOperationEnqueued(op);
            }
        }
    };
    OfflineQueue.prototype.onOperationRequeued = function (op) {
        for (var _i = 0, _a = this.listeners; _i < _a.length; _i++) {
            var listener = _a[_i];
            if (listener.onOperationRequeued) {
                listener.onOperationRequeued(op);
            }
        }
    };
    OfflineQueue.prototype.onOperationSuccess = function (op, result) {
        for (var _i = 0, _a = this.listeners; _i < _a.length; _i++) {
            var listener = _a[_i];
            if (listener.onOperationSuccess) {
                listener.onOperationSuccess(op, result);
            }
        }
    };
    OfflineQueue.prototype.onOperationFailure = function (op, error) {
        for (var _i = 0, _a = this.listeners; _i < _a.length; _i++) {
            var listener = _a[_i];
            if (listener.onOperationFailure) {
                listener.onOperationFailure(op, error);
            }
        }
    };
    OfflineQueue.prototype.queueCleared = function () {
        for (var _i = 0, _a = this.listeners; _i < _a.length; _i++) {
            var listener = _a[_i];
            if (listener.queueCleared) {
                listener.queueCleared();
            }
        }
    };
    OfflineQueue.prototype.onForwardNext = function (entry, result) {
        if (result.errors) {
            // TODO distiguish between application errors that happen here
            // And other errors that may happen in forwardOperation
            this.onOperationFailure(entry.operation, result.errors);
            // Notify for success otherwise
        }
        else if (result.data) {
            this.onOperationSuccess(entry.operation, result);
        }
        this.dequeueOperation(entry);
        if (this.entries.length === 0) {
            this.queueCleared();
        }
    };
    return OfflineQueue;
}());
exports.OfflineQueue = OfflineQueue;
/**
 *
 * Helper function to generate a simple alphanumeric ID for queue entries
 */
function ID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return "queue:" + Math.random().toString(36).substr(2, 9);
}
;
