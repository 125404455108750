"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApolloOfflineClientConfig = void 0;
var offix_scheduler_1 = require("offix-scheduler");
var offix_conflicts_client_1 = require("offix-conflicts-client");
var cache_1 = require("../cache");
var apollo_1 = require("../apollo");
/**
 * Class for managing user and default configuration.
 * Default config is applied on top of user provided configuration
 */
var ApolloOfflineClientConfig = /** @class */ (function () {
    function ApolloOfflineClientConfig(options) {
        if (options === void 0) { options = {}; }
        this.retryOptions = {
            delay: {
                initial: 1000,
                max: Infinity,
                jitter: true
            },
            attempts: {
                max: 5
            }
        };
        Object.assign(this, options);
        this.cacheStorage = options.cacheStorage || cache_1.createDefaultCacheStorage();
        this.offlineStorage = options.offlineStorage || offix_scheduler_1.createDefaultOfflineStorage();
        this.conflictStrategy = options.conflictStrategy || offix_conflicts_client_1.UseClient;
        this.conflictProvider = options.conflictProvider || new offix_conflicts_client_1.VersionedState();
        this.conflictListener = new apollo_1.CompositeConflictListener();
        if (options.conflictListener) {
            this.conflictListener.addConflictListener(options.conflictListener);
        }
        this.link = apollo_1.createDefaultLink(this);
    }
    return ApolloOfflineClientConfig;
}());
exports.ApolloOfflineClientConfig = ApolloOfflineClientConfig;
