"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfflineStore = void 0;
var OfflineStoreSerializer_1 = require("./OfflineStoreSerializer");
/**
 * Abstract Offline storage
 */
var OfflineStore = /** @class */ (function () {
    function OfflineStore(storage, serializer) {
        this.initialized = false;
        this.offlineMetaKey = "offline-meta-data";
        this.storageVersion = "v1";
        this.arrayOfKeys = [];
        this.storage = storage;
        this.serializer = serializer || new OfflineStoreSerializer_1.DefaultOfflineSerializer();
    }
    /**
     * Init store
     */
    OfflineStore.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var keys;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.getItem(this.offlineMetaKey)];
                    case 1:
                        keys = _a.sent();
                        this.arrayOfKeys = keys || [];
                        this.initialized = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Save an entry to store
     *
     * @param entry - the entry to be saved
     */
    OfflineStore.prototype.saveEntry = function (entry) {
        return __awaiter(this, void 0, void 0, function () {
            var serialized, offlineKey;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        serialized = this.serializer.serializeForStorage(entry);
                        offlineKey = this.getOfflineKey(entry.qid);
                        if (!!this.arrayOfKeys.includes(offlineKey)) return [3 /*break*/, 2];
                        this.arrayOfKeys.push(offlineKey);
                        return [4 /*yield*/, this.storage.setItem(this.offlineMetaKey, this.arrayOfKeys)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.storage.setItem(offlineKey, serialized)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Remove an entry from the store
     *
     * @param queue - the entry to be removed
     */
    OfflineStore.prototype.removeEntry = function (entry) {
        return __awaiter(this, void 0, void 0, function () {
            var offlineKey;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.arrayOfKeys.splice(this.arrayOfKeys.indexOf(entry.qid), 1);
                        this.storage.setItem(this.offlineMetaKey, this.arrayOfKeys);
                        offlineKey = this.getOfflineKey(entry.qid);
                        return [4 /*yield*/, this.storage.removeItem(offlineKey)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Fetch data from the offline store
     */
    OfflineStore.prototype.getOfflineData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var offlineItems, _i, _a, key, keyVersion, item, deserializedItem;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        offlineItems = [];
                        _i = 0, _a = this.arrayOfKeys;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        key = _a[_i];
                        keyVersion = key.split(":")[0];
                        if (!(keyVersion === this.storageVersion)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.storage.getItem(key)];
                    case 2:
                        item = _b.sent();
                        deserializedItem = this.serializer.deserializeFromStorage(item);
                        offlineItems.push({
                            operation: {
                                op: deserializedItem,
                                qid: key.slice(this.storageVersion.length + 1) // remove the 'v1:' from the key when we put it back in the queue
                            }
                        });
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, offlineItems];
                }
            });
        });
    };
    OfflineStore.prototype.getOfflineKey = function (id) {
        return this.storageVersion + ":" + id;
    };
    return OfflineStore;
}());
exports.OfflineStore = OfflineStore;
