"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompositeConflictListener = void 0;
/**
 * Composite Conflict Listener class that can accept register and remove individual listener functions as needed
 * Gets passed down to the conflict link
 */
var CompositeConflictListener = /** @class */ (function () {
    function CompositeConflictListener() {
        this.listeners = [];
    }
    CompositeConflictListener.prototype.addConflictListener = function (listener) {
        this.listeners.push(listener);
    };
    CompositeConflictListener.prototype.removeConflictListener = function (listener) {
        var index = this.listeners.indexOf(listener);
        if (index >= 0) {
            this.listeners.splice(index, 1);
        }
    };
    CompositeConflictListener.prototype.mergeOccurred = function (operationName, resolvedData, server, client) {
        for (var _i = 0, _a = this.listeners; _i < _a.length; _i++) {
            var listener = _a[_i];
            if (listener.mergeOccurred) {
                listener.mergeOccurred(operationName, resolvedData, server, client);
            }
        }
    };
    CompositeConflictListener.prototype.conflictOccurred = function (operationName, resolvedData, server, client) {
        for (var _i = 0, _a = this.listeners; _i < _a.length; _i++) {
            var listener = _a[_i];
            if (listener.conflictOccurred) {
                listener.conflictOccurred(operationName, resolvedData, server, client);
            }
        }
    };
    return CompositeConflictListener;
}());
exports.CompositeConflictListener = CompositeConflictListener;
